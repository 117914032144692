import React from "react";
import { Checkbox } from "@nextui-org/checkbox";
import { Progress } from "@nextui-org/progress";
import "./App.css";
import { InitTodo, getTodos, startStream, writeTodo } from "./fauna";
import { useTabVisibility } from "./useTabVisibility";
import { AddTodo } from "./AddTodo";
import { FunctionProvider, SetFunctionContext } from "./refreshContext";

type TodoState = Record<string, InitTodo>;

function TodoRow({
  todo,
  onValueChange,
}: {
  todo: InitTodo;
  onValueChange: (id: string, checked: boolean) => void;
}) {
  const [checkedTodoIdState, setCheckedTodoIdState] = React.useState<
    string | null
  >(todo.checkedTodoId);
  const [checked, setChecked] = React.useState(todo.checked);
  const [timeout, setTimeoutState] = React.useState<NodeJS.Timeout | null>(
    null
  );

  const onValueChangeLocal = (isSelected: boolean, write = true) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setChecked(isSelected);
    if (write) {
      writeTodo(todo.id, isSelected);
    }
    setTimeoutState(
      setTimeout(() => {
        onValueChange(todo.id, isSelected);
      }, 700)
    );
  };

  React.useEffect(() => {
    const effect = async () => {
      if (checkedTodoIdState !== null) {
        const stream = startStream(checkedTodoIdState, (selected) =>
          onValueChangeLocal(selected, false)
        );
        return () => stream.close();
      } else {
        const res = await writeTodo(todo.id, todo.checked);
        setCheckedTodoIdState(res.ref.value.id);
      }
    };
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTodoIdState]);

  return (
    <div className="flex Checkbox">
      <Checkbox
        radius="sm"
        size="lg"
        isSelected={checked}
        lineThrough
        onValueChange={onValueChangeLocal}
      >
        {todo.text}
      </Checkbox>
    </div>
  );
}

function setChecked(todos: TodoState, todoId: string, checked: boolean) {
  const todo = todos[todoId];
  return { ...todos, [todoId]: { ...todo, checked } };
}

function TodoList({ initialTodos }: { initialTodos: InitTodo[] }) {
  const initialState: TodoState = initialTodos.reduce((acc, todo) => {
    return { ...acc, [todo.id]: todo };
  }, {} as TodoState);
  const [todos, updateTodos] = React.useState<TodoState>(initialState);

  React.useEffect(() => {
    updateTodos(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTodos]);

  const notCompleted = Object.values(todos)
    .filter(({ checked }) => !checked)
    .sort((a, b) => a.index - b.index);
  const completed = Object.values(todos)
    .filter(({ checked }) => checked)
    .sort((a, b) => a.index - b.index);

  const onValueChange = (id: string, checked: boolean) => {
    updateTodos((todos) => setChecked(todos, id, checked));
  };

  return (
    <div>
      {notCompleted.map((todo) => {
        return (
          <TodoRow
            key={"unchecked" + todo.text}
            todo={todo}
            onValueChange={onValueChange}
          />
        );
      })}
      {completed.map((todo) => {
        return (
          <TodoRow
            key={"checked" + todo.text}
            todo={todo}
            onValueChange={onValueChange}
          />
        );
      })}
    </div>
  );
}

function TodoWrapper() {
  const [todos, setTodos] = React.useState<InitTodo[] | null>(null);
  const setFunc = React.useContext(SetFunctionContext);
  const callback = React.useMemo(() => {
    return () => {
      const init = async () => {
        console.log("Getting todos");
        const initTodos = await getTodos();
        setTodos(initTodos);
      };
      init();
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(callback, []);
  React.useEffect(() => {
    setFunc !== null && setFunc(() => callback);
  }, [callback, setFunc]);
  useTabVisibility(callback);

  if (todos !== null) {
    return <TodoList initialTodos={todos} />;
  } else {
    return (
      <div>
        Loading
        <Progress
          size="sm"
          isIndeterminate
          aria-label="Loading..."
          className="max-w-md"
        />
      </div>
    );
  }
}

export default function App() {
  return (
    <FunctionProvider>
      <div className="Main">
        <img
          alt="sailboat"
          className="Boat"
          src="/Rosie.jpg"
          width="200px"
          height="200px"
        />
        <div>
          <TodoWrapper />
        </div>
        <AddTodo />
      </div>
    </FunctionProvider>
  );
}
