const SALT = [
  49, 111, 31, 140, 4, 34, 137, 174, 69, 137, 93, 174, 165, 96, 67, 55,
];

async function deriveKeyFromPassword(password: string) {
  // First, convert the password and salt to an ArrayBuffer
  const passwordBuffer = new TextEncoder().encode(password);
  const saltBuffer = new Uint8Array(SALT).buffer;

  // Import the password as a raw key
  const importedKey = await window.crypto.subtle.importKey(
    "raw",
    passwordBuffer,
    { name: "PBKDF2" },
    false,
    ["deriveKey", "deriveBits"]
  );

  // Derive a key using PBKDF2 with SHA-256 and HMAC
  const derivedKey = await window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: saltBuffer,
      iterations: 100000, // Recommended number of iterations. Adjust as needed.
      hash: "SHA-256",
    },
    importedKey,
    // Define the derived key details (AES-GCM in this example)
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  );

  return derivedKey;
}

export async function decryptData(
  iv: number[],
  encryptedData: number[],
  password: string
) {
  const key = await deriveKeyFromPassword(password);

  const decryptedData = await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: new Uint8Array(iv).buffer,
    },
    key,
    new Uint8Array(encryptedData).buffer
  );

  return new TextDecoder().decode(decryptedData);
}

export async function encryptString(password: string, plaintext: string) {
  // Convert string to ArrayBuffer
  const encoder = new TextEncoder();
  const plaintextBuffer = encoder.encode(plaintext);
  const key = await deriveKeyFromPassword(password);

  // Generate a random IV
  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  // Encrypt the plaintext
  const ciphertextBuffer = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    plaintextBuffer
  );

  return {
    ciphertext: new Uint8Array(ciphertextBuffer),
    iv: iv,
  };
}
