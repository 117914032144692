import React from "react";

type FunctionContextType = (() => void) | null;

export const FunctionContext = React.createContext<FunctionContextType>(null);
export const SetFunctionContext = React.createContext<React.Dispatch<
  React.SetStateAction<FunctionContextType>
> | null>(null);

type FunctionProviderProps = {
  children: React.ReactNode;
};

export const FunctionProvider: React.FC<FunctionProviderProps> = ({
  children,
}) => {
  const [func, setFunc] = React.useState<FunctionContextType>(null);

  return (
    <FunctionContext.Provider value={func}>
      <SetFunctionContext.Provider value={setFunc}>
        {children}
      </SetFunctionContext.Provider>
    </FunctionContext.Provider>
  );
};
