import { useEffect } from "react";

// Type for the callback function
type VisibilityCallback = () => void;

export function useTabVisibility(callback: VisibilityCallback) {
  useEffect(() => {
    function handleVisibilityChange() {
      console.log("Visiblity change", document.hidden);
      if (!document.hidden) {
        callback();
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup the listener when the component is unmounted
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [callback]); // `callback` dependency to ensure the listener is updated if the callback changes
}
