import { query, Client } from "faunadb";

const q = query;

const client = new Client({
  secret: process.env.REACT_APP_FAUNA_CLIENT_SECRET || "",
});

interface TodoType {
  data: { title: string };
  ref: { value: { id: string } };
}

export interface InitTodo {
  text: string;
  checked: boolean;
  id: string;
  checkedTodoId: string | null;
  index: number;
}

interface CheckedTodo {
  data: { todoId: string; checked: boolean; date: string };
  ref: { value: { id: string } };
}

export function startStream(
  checkedTodoId: string,
  callback: (checked: boolean) => void
) {
  // console.log("Subscribing to", checkedTodoId);
  const docRef = q.Ref(q.Collection("checked_todos"), checkedTodoId);
  const s = client.stream
    .document(docRef)
    .on("snapshot", (data) => {
      // console.log("snapshot", data);
    })
    .on("version", (data) => {
      const checkedTodo = data.document as CheckedTodo;
      // console.log("checkedTodo", checkedTodo);
      callback(checkedTodo.data.checked);
      // console.log("version", data);
    })
    .on("error", (error) => {
      console.log("Error:", error);
    })
    .start();
  return s;
}

function getCurrentDate() {
  const today = new Date();

  const year = today.getFullYear();

  // Add 1 to month since it's 0-indexed and pad with '0' if needed
  const month = String(today.getMonth() + 1).padStart(2, "0");

  // Pad day with '0' if needed
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

async function getTodaysChecked(): Promise<CheckedTodo[]> {
  try {
    const res: { data: CheckedTodo[] } = await client.query(
      q.Map(
        q.Paginate(q.Match(q.Index("date"), getCurrentDate())),
        q.Lambda("eventRef", q.Get(q.Var("eventRef")))
      )
    );
    console.log("checked", res.data[0]);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function getTodoTypes(): Promise<TodoType[]> {
  try {
    const res: { data: TodoType[] } = await client.query(
      q.Map(
        q.Paginate(q.Documents(q.Collection("todos_types"))),
        q.Lambda("ref", q.Get(q.Var("ref")))
      )
    );
    console.log(res.data[0]);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

interface WroteTodo {
  ref: { value: { id: string } };
}

export async function createTodo(
  title: string,
  credentials: string
): Promise<WroteTodo> {
  const createClient = new Client({
    secret: credentials,
  });
  const query = q.Create(q.Collection("todos_types"), {
    data: { title },
  });

  const res: WroteTodo = await createClient.query(query);
  console.log("executed", res);
  return res;
}

export async function writeTodo(
  id: string,
  checked: boolean
): Promise<WroteTodo> {
  const today = getCurrentDate();
  const query = q.If(
    q.Exists(q.Match(q.Index("checkedDate"), id, today)),
    q.Update(
      q.Select("ref", q.Get(q.Match(q.Index("checkedDate"), id, today))),
      {
        data: { checked },
      }
    ),
    q.Create(q.Collection("checked_todos"), {
      data: { todoId: id, checked, date: today },
    })
  );

  const res: WroteTodo = await client.query(query);
  console.log("executed", res);
  return res;
}

export async function getTodos(): Promise<InitTodo[]> {
  try {
    const [checked, types] = await Promise.all([
      getTodaysChecked(),
      getTodoTypes(),
    ]);

    const res = types.map((todoType, i) => {
      const checkedTodo = checked.find(
        (checkedTodo) => checkedTodo.data.todoId === todoType.ref.value.id
      );
      const isChecked = checkedTodo ? checkedTodo.data.checked : false;
      return {
        text: todoType.data.title,
        checked: isChecked,
        index: i,
        checkedTodoId: checkedTodo?.ref.value.id || null,
        id: todoType.ref.value.id,
      };
    });

    console.log("got all todos", res);

    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
