import { Button } from "@nextui-org/button";
import { Input } from "@nextui-org/input";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/modal";
import React from "react";
import { decryptData } from "./crypto";
import jsonData from "./encrypted.json";
import { readFromLocalStorage, saveToLocalStorage } from "./localStorage";
import { createTodo } from "./fauna";
import { FunctionContext } from "./refreshContext";
const { iv, ciphertext } = jsonData;

const LoginModal = ({
  setCredentials,
  onClose,
}: {
  setCredentials: (credentials: string) => void;
  onClose: () => void;
}) => {
  const [incorrectPass, setIncorrectPass] = React.useState(false);
  const [password, setPassword] = React.useState<string | null>(null);
  const [tmpPass, setTmpPass] = React.useState("");
  React.useEffect(() => {
    const pass = readFromLocalStorage();
    if (pass) {
      setPassword(pass);
    }
  }, []);
  React.useEffect(() => {
    if (password) {
      const run = async () => {
        try {
          const dec = await decryptData(iv, ciphertext, password);

          saveToLocalStorage(password);
          setCredentials(dec);
        } catch (e) {
          setIncorrectPass(true);
          console.log(e);
        }
      };
      run();
    }
  }, [password, setCredentials]);
  return (
    <>
      <ModalHeader>Logga in</ModalHeader>
      <ModalBody>
        <Input
          autoFocus
          value={tmpPass}
          isInvalid={incorrectPass}
          errorMessage={incorrectPass && "Fel lösenord"}
          onValueChange={setTmpPass}
          placeholder="Lösenord"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" variant="light" onPress={onClose}>
          Avbryt
        </Button>
        <Button color="primary" onPress={() => setPassword(tmpPass)}>
          Logga in
        </Button>
      </ModalFooter>
    </>
  );
};

const TodoModal = ({
  onClose,
  credentials,
}: {
  onClose: () => void;
  credentials: string;
}) => {
  const [value, setValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const refresh = React.useContext(FunctionContext);
  const createTodoFn = async (val: string) => {
    setLoading(true);
    await createTodo(val, credentials);
    setLoading(false);
    refresh && refresh();
    onClose();
  };
  return (
    <>
      <ModalHeader>Lägg till ny todo</ModalHeader>
      <ModalBody>
        <Input
          autoFocus
          value={value}
          placeholder="Vad ska göras"
          onValueChange={setValue}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          variant="light"
          isDisabled={loading}
          onPress={onClose}
        >
          Avbryt
        </Button>
        <Button
          color="primary"
          isDisabled={value.trim() === "" || loading}
          onPress={() => createTodoFn(value)}
        >
          {loading ? "Sparar..." : "Lägg till"}
        </Button>
      </ModalFooter>
    </>
  );
};

const AddModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const [credentials, setCredentials] = React.useState<string | null>(null);

  return (
    <Modal isOpen={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
      <ModalContent>
        {(onClose) =>
          credentials ? (
            <TodoModal onClose={onClose} credentials={credentials} />
          ) : (
            <LoginModal onClose={onClose} setCredentials={setCredentials} />
          )
        }
      </ModalContent>
    </Modal>
  );
};

export const AddTodo = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)}>
        Lägg till ny todo
      </Button>
      <AddModal open={open} setOpen={setOpen} />
    </>
  );
};
