const KEY = "rp_token";
export const saveToLocalStorage = (value: string) => {
  try {
    localStorage.setItem(KEY, value);
  } catch (err) {
    console.error("Failed to save to localStorage:", err);
  }
};

export const readFromLocalStorage = (): string | null => {
  try {
    return localStorage.getItem(KEY);
  } catch (err) {
    console.error("Failed to read from localStorage:", err);
    return null;
  }
};
